import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SharedService } from './shared.service'

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }),  };

  constructor(private _sharedService: SharedService, private _httpClient: HttpClient) { }

  GetStates(data: string): Observable<any> {
    return this._httpClient.get(this._sharedService.getAPIurl() + '/Common/GetStates?searchStates=' + data).pipe(
      map((response: Response) => <any>response),
      catchError(this._errorHandler));
  }

  GetCityes(stateId: Number, searchCity: string): Observable<any> {
    return this._httpClient.get(this._sharedService.getAPIurl() + '/Common/GetCityes?stateId=' + stateId + '&searchCity=' + searchCity).pipe(
      map((response: Response) => <any>response),
      catchError(this._errorHandler));
  }

  GetCustomers(data: string): Observable<any> {
    return this._httpClient.get(this._sharedService.getAPIurl() + '/Common/GetCustomers?searchCusomer=' + data).pipe(
      map((response: Response) => <any>response),
      catchError(this._errorHandler));
  }
  GetCustomerType(): Observable<any> {
    return this._httpClient.get(this._sharedService.getAPIurl() + '/Campaign/GetCustomerTypes').pipe(
      map((response: Response) => <any>response),
      catchError(this._errorHandler));

  }

  getAggregatorType(IsAdminUser: string, UserId: string): Observable<any> {
    return this._httpClient.get(this._sharedService.getAPIurl() + '/Common/GetAggregatorType?IsAdminUser=' + IsAdminUser + '&UserId=' + UserId).pipe(
      map((response: Response) => <any>response),
      catchError(this._errorHandler));

  }
  
  getAggregatorAccountBalance(UserId: string): Observable<any> {
    return this._httpClient.get(this._sharedService.getAPIurl() + '/Common/getAggregatorAccountBalance?&UserId=' + UserId).pipe(
      map((response: Response) => <any>response),
      catchError(this._errorHandler));

  }
  GetCustomerList(customerTypeId : Number): Observable<any> {
    return this._httpClient.get(this._sharedService.getAPIurl() + '/CustomerMaster/GetCustomer?customerTypeId='+customerTypeId+'').pipe(
      map((response: Response) => <any>response),
      catchError(this._errorHandler));
  }

  _errorHandler(error: Response) {
    if(this._sharedService==undefined)
    {
      this._sharedService= new SharedService();
    }
    this._sharedService.hideLoader();
    return Observable.throw(error || "Internal server error");
  }

}
