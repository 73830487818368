import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes, Router, NavigationEnd, ActivatedRoute, NavigationStart, NavigationCancel, NavigationError } from '@angular/router';
import { map, filter } from 'rxjs/operators';
import { SharedService } from '../../shared/components/shared.service';
import { CommonService } from '../../shared/components/common.service';
import { userdata } from '../new-agent-registration/new-agent-registration.model';
import { newagentregistrationservice } from '../new-agent-registration/new-agent-registration.service';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-new-agent-registration',
  templateUrl: './new-agent-registration.component.html',
  styleUrls: ['./new-agent-registration.component.css']
})
export class NewAgentRegistrationComponent implements OnInit {
  filter: userdata;
  State: any;
  City: any;
  PageHeader: any;
  model;
  Language: any;
  successmsgs: any;
  sendpass: any;
  dropdownSettings: { singleSelection: boolean; text: string; selectAllText: string; unSelectAllText: string; enableSearchFilter: boolean; classes: string; };
  selectedItems: { id: number; itemName: string; }[];
  dropdownList: { id: number; itemName: string; }[];
  selected: any[];
  lang: any;
  displayPosition: boolean;

  position: string;
  PopUpDisplay: boolean;
  ShowPassportPhoto: boolean;
  url: any;
  result: any;
  fileToUpload: any;
  PassportPhotoimageUrl: any;
  passportphotoevent: any;
  PassportPopup: boolean;
  AadhaarCardimageUrl: any;
  aadhaarcardPopup: boolean;
  Showaadharcard: boolean;
  aadhaarphotoevent: any;
  panphotoevent: any;
  Showpancard: boolean;
  pancardPopup: boolean;
  PanCardimageUrl: any;
  CancelledCheqevent: any;
  CancelledCheqPopup: boolean;
  ShowCancelledCheq: boolean;
  CancelledCheqimageUrl: any;
  UserId: any;
  file: any;
  PassportPhotoName: any;
  AadhaarCardName: any;
  PanCardName: any;
  CancelledCardName: any;
  PassportPopupPDF: boolean;
  aadhaarcardPopuppdf: boolean;
  pancardPopuppdf: boolean;
  CancelledCheqPopuppdf: boolean;
    Submityespopup: boolean;
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private _commonService: CommonService,
    public _SharedService: SharedService, private _userService: newagentregistrationservice, private datePipe: DatePipe
    , public sanitizer: DomSanitizer) {
    this.filter = new userdata();
    this.ShowPassportPhoto = false;
  }

  ngOnInit() {
    this.Submityespopup = false;
    this._SharedService.hideLoader();
    //this._sharedService.IsLogedIn.isLogedIn = true;
    this.getstate();
    this.GetLanguage();

  }

  getstate() {
   
    this._userService.GetState().subscribe(
      data => {
        if (data) {
          this.State = data;
        }
      });
  }
  GetCity(StateID) {
   
    let filter = new userdata();
    filter.StateID = StateID;
    this._userService.GetCity(filter).subscribe(
      data => {
        if (data) {
          this.City = data;
        }
      });
  }
  GetLanguage() {
   
    this._userService.GetLanguage().subscribe(
      data => {
        if (data) {
          this.Language = data;
        }
      });
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  validation(Email) {
   
    var form = document.getElementById("form");
    var Email = Email;
    var text = document.getElementById("text");
    var pattern = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;

    if (Email.match(pattern)) {
      form.classList.add("Vaild");
      form.classList.remove("InVaild");
      text.innerHTML = "";
      text.style.color = "#ffffff";

    }
    else {
      form.classList.remove("Vaild");
      form.classList.add("InVaild");
      text.innerHTML = "your email address is invaild";
      text.style.color = "#ff0000";

    }
  }

  CheckForCreateUserEmail(i) {
    this._SharedService.showLoader();
    this.UserId = 0;
    this.Submityespopup = true;
    let filter = new userdata();
    filter.AggregratorID = i.AggregratorID;
    filter.FirstName = i.FirstName.trim();
    filter.LastName = i.LastName.trim();
    filter.Email = i.Email.trim();
    filter.ContactNo = i.ContactNo.trim();
    if (i.selectedDOB == "") {
      filter.selectedDOB = "";
    } else {
      filter.selectedDOB = moment(this.datePipe.transform(i.selectedDOB)).format('DD MMM YY');
    }
    filter.Status = i.Status;
    filter.Address = i.Address.trim();
    filter.StateID = i.StateID;
    filter.CityID = i.CityID;
    filter.PinCode = i.PinCode;
    filter.Organization = i.Organization;
    filter.employmentstatus = i.employmentstatus;
    filter.AccountNo = i.AccountNo;
    filter.BankName = i.BankName;
    filter.BranchName = i.BranchName;
    filter.IFSCcode = i.IFSCcode;
    let arrayOfObjects = i.LanguageID;
    let LanguageID = arrayOfObjects.map(x => x.LanguageID).join(", ");
    filter.LanguageID = LanguageID;
    filter.Locality = i.Locality.trim();

    this.CreateNewagentRegi(filter);

  }
  CreateNewagentRegi(filter) {
   

    this._userService.CreateNewagentRegi(filter).subscribe(
      data => {
        //this.SendPasswordToEmail(filter);
        this.UserId = data.Table[0].UserId;
        this.InsertAggregratorKycMaster(this.UserId);

      });
  }
  //SendPasswordToEmail(filter) {
  //  this._userService.SendPasswordToEmail(filter).subscribe(
  //    data => {
  //      if (data) {
  //        this.sendpass = data;
  //      }
  //    });
  //}
  Reset() {
    this.filter.UserId = "";
    this.filter.FirstName = "";
    this.filter.LastName = "";
    this.filter.Email = "";
    this.filter.ContactNo = "";
    this.filter.selectedDOB = "";
    this.filter.Status = 1;
    this.filter.Address = "";
    this.filter.StateID = "";
    this.filter.CityID = "";
    this.filter.PinCode = "";
    this.filter.Organization = "";
    this.filter.employmentstatus = "";
    this.filter.LanguageID = "";
    this.filter.Locality = "";
    this.filter.PassportPhotoFile = "";
    this.filter.AadharPhotoFile = "";
    this.filter.PanCardPhotoFile = "";
    this.filter.CancelledChequePhoto = "";
    this.passportphotoevent = "";
    this.aadhaarphotoevent = "";
    this.panphotoevent = "";
    this.CancelledCheqevent = "";
    this.ShowPassportPhoto = false;
    this.Showaadharcard = false;
    this.Showpancard = false;
    this.ShowCancelledCheq = false;
    this.PassportPhotoName = "";
    this.AadhaarCardName = "";
    this.PanCardName = "";
    this.CancelledCardName = "";
    this.filter.AccountNo = "";
    this.filter.BankName = "";
    this.filter.BranchName = "";
    this.filter.IFSCcode = "";
    this.Submityespopup = false;
    this._SharedService.hideLoader();
    this.filter.chkTC = false;
    this.City = [];
    this.getstate();
    this.GetLanguage();
  }
  viewPopup(i) {
    debugger
    if (i.FirstName.trim() == "") {
      this.successmsgs = this._SharedService.showError('Please enter first name.', '');
      return true;
    }
    if (i.LastName.trim() == "") {
      this.successmsgs = this._SharedService.showError('Please enter last name.', '');
      return true;
    }
    if (i.Email.trim() == "") {
      this.successmsgs = this._SharedService.showError('Please enter email address.', '');
      return true;
    }
    if (i.Email != "") {
      var form = document.getElementById("form");
      var Email = i.Email;
      var text = document.getElementById("text");
      var pattern = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;

      if (Email.match(pattern)) {
        form.classList.add("Vaild");
        form.classList.remove("InVaild");
        // text.innerHTML = "your email address in vaild";
        // text.style.color = "#00ff00";

      }
      else {
        form.classList.remove("Vaild");
        form.classList.add("InVaild");
        text.innerHTML = "Please enter vaild email address";
        text.style.color = "#ff0000";
        this.successmsgs = this._SharedService.showError('Please enter vaild email address.', '');
        return true;
      }

    }

    if (i.ContactNo.trim() == "" || i.ContactNo.length < 10) {
      this.successmsgs = this._SharedService.showError('Please enter contact no.', '');
      return true;
    }
    if (i.LanguageID == "") {
      this.successmsgs = this._SharedService.showError('Please select Language.', '');
      return true;
    }
    if (i.Locality.trim() == "") {
      this.successmsgs = this._SharedService.showError('Please enter your locality.', '');
      return true;
    }

    if (i.StateID == "") {
      this.successmsgs = this._SharedService.showError('Please select state.', '');
      return true;
    }
    if (i.CityID == "") {
      this.successmsgs = this._SharedService.showError('Please select city.', '');
      return true;
    }
    if (i.LanguageID == "") {
      this.successmsgs = this._SharedService.showError('Please select Language.', '');
      return true;
    }
    if (i.LanguageID.length > 5) {
      this.successmsgs = this._SharedService.showError('Please select only 5 language.', '');
      return true;
    }
    if (i.PinCode == "") {
      this.successmsgs = this._SharedService.showError('Please enter pincode.', '');
      return true;
    }
    if (i.PinCode.length != 6) {
      this.successmsgs = this._SharedService.showError('Please enter valid pincode.', '');
      return true;
    }
    if (i.AccountNo.trim() == "") {
      this.successmsgs = this._SharedService.showError('Please enter account no.', '');
      return true;
    }
    if (i.BankName.trim() == "") {
      this.successmsgs = this._SharedService.showError('Please enter bank name.', '');
      return true;
    }
    if (i.BranchName.trim() == "") {
      this.successmsgs = this._SharedService.showError('Please enter branch name.', '');
      return true;
    }
    if (i.IFSCcode.trim() == "") {
      this.successmsgs = this._SharedService.showError('Please enter IFSC code.', '');
      return true;
    }
    if (i.chkTC == false) {
      this.successmsgs = this._SharedService.showError('Please read and accept the terms and conditions.', '');
      return true;
    }
    //if (i.PassportPhotoFile == "") {
    //  this.successmsgs = this._SharedService.showError('Please add passport sized photo.', '');
    //  return true;
    //}
    //if (i.AadharPhotoFile == "") {
    //  this.successmsgs = this._SharedService.showError('Please add aadhaar card photo.', '');
    //  return true;
    //}
    //if (i.PanCardPhotoFile == "") {
    //  this.successmsgs = this._SharedService.showError('Please add pan card photo.', '');
    //  return true;
    //}
    //if (i.CancelledChequePhoto == "") {
    //  this.successmsgs = this._SharedService.showError('Please add cancelled cheque.', '');
    //  return true;
    //}
    let filter = new userdata();
    filter.AggregratorID = i.AggregratorID;
    filter.FirstName = i.FirstName.trim();
    filter.LastName = i.LastName.trim();
    filter.Email = i.Email.trim();
    filter.ContactNo = i.ContactNo.trim();
    if (i.selectedDOB == "") {
      filter.selectedDOB = "";
    } else {
      filter.selectedDOB = moment(this.datePipe.transform(i.selectedDOB)).format('DD MMM YY');
    }
    filter.Address = i.Address.trim();
    filter.StateID = i.StateID;
    filter.CityID = i.CityID;
    filter.PinCode = i.PinCode;
    filter.Organization = i.Organization;
    filter.employmentstatus = i.employmentstatus;

    let arrayOfObjects = i.LanguageID;
    let LanguageID = arrayOfObjects.map(x => x.LanguageID).join(", ");
    filter.LanguageID = LanguageID;
    filter.Locality = i.Locality.trim();


    this._userService.CheckForCreateUserEmail(filter).subscribe(
      data => {
       
        if (data) {
          this.PopUpDisplay = true;
          if (data[0].Email == true) {
            this.successmsgs = this._SharedService.showError('Already emailid exist.', '');
            this.PopUpDisplay = false;
            return true;
          }
          if (data[0].Contact == true) {
            this.successmsgs = this._SharedService.showError('Already contact no exist.', '');
            this.PopUpDisplay = false;
            return true;
          }


        }
      });

  }
  closeModal(id: string) {

    this.PopUpDisplay = false;
  }

  onPassportPhotoChanged(event) {
   
    this.ShowPassportPhoto = false;
    this.passportphotoevent = event;
    if (event.target.files[0] == undefined || event.target.files[0] == null) {
      this.filter.PassportPhotoFile = "";
    }
    else {
    if (event.target.files[0].type === 'image/jpeg' ||
      event.target.files[0].type === 'image/png' ||
      event.target.files[0].type === 'image/jpg' ||
      event.target.files[0].type === 'application/pdf') {
      if (event.target.files.length > 0) {
        if (event.target.files[0].size < 1048577) {
          this.filter.PassportPhotoFile = event.target.files[0];
        
        }
        else {
          alert("Maximum size should be 1 mb");
          this.filter.PassportPhotoFile = "";
          return true;
        }
      }
    }
    else {
      this.filter.PassportPhotoFile = "";
    }
    }
  }
  ShowSelectedPassportPhoto() {
   
    if (this.passportphotoevent.target.files[0] == undefined || this.passportphotoevent.target.files[0] == null) {
      this.ShowPassportPhoto = false;
    }
    else {
      this.ShowPassportPhoto = true;
      this.PassportPhotoName = this.passportphotoevent.target.files[0].name;
    }
  }
  ShowPassportPic() {
   
    this.fileToUpload = this.passportphotoevent.target.files.item(0);
    //Show image preview
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.PassportPhotoimageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
    if (this.fileToUpload.type == 'application/pdf') {
      this.PassportPopupPDF = true;
    }
    else {
      this.PassportPopup = true;
    }
  }

  PassportcloseModal(id: string) {
    this.PassportPopup = false;
  }
  PassportpdfcloseModal(id: string) {
    this.PassportPopupPDF = false;
  }
  ClosePassportPhoto() {
    debugger;
    this.ShowPassportPhoto = false;
    this.passportphotoevent = "";
    this.PassportPhotoName = "";
    this.filter.PassportPhotoFile = "";
  }
  onAadhaarCardChanged(event) {
   
    this.Showaadharcard = false;
    this.aadhaarphotoevent = event;
    if (event.target.files[0] == undefined || event.target.files[0] == null) {
      this.filter.AadharPhotoFile = "";
    }
    else {
      if (event.target.files[0].type === 'image/jpeg' ||
        event.target.files[0].type === 'image/png' ||
        event.target.files[0].type === 'image/jpg' ||
        event.target.files[0].type === 'application/pdf') {
        if (event.target.files.length > 0) {
          if (event.target.files[0].size < 1048577) {
            this.filter.AadharPhotoFile = event.target.files[0];
          }
          else {
            alert("Maximum size should be 1 mb");
            this.filter.AadharPhotoFile = "";
            return true;
          }
        }
        else {
          this.filter.AadharPhotoFile = "";
        }
      }
    }
  }
  ShowSelectedAadhaarCard() {
    if (this.aadhaarphotoevent.target.files[0] == undefined || this.aadhaarphotoevent.target.files[0] == null) {
      this.Showaadharcard = false;
    }
    else {
      this.Showaadharcard = true;
      this.AadhaarCardName = this.aadhaarphotoevent.target.files[0].name;
    }
  }
  ShowAadhaarPic() {
   
    this.fileToUpload = this.aadhaarphotoevent.target.files.item(0);
    //Show image preview
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.AadhaarCardimageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
    if (this.fileToUpload.type == 'application/pdf') {
      this.aadhaarcardPopuppdf= true;
    }
    else {
      this.aadhaarcardPopup = true;
    }
    
  }

  AadhaarcloseModal(id: string) {
    this.aadhaarcardPopup = false;
  }
  AadhaarpdfcloseModal(id: string) {
    this.aadhaarcardPopuppdf = false;
  }
  CloseAadhaarCard() {
    this.Showaadharcard = false;
    this.aadhaarphotoevent = "";
    this.AadhaarCardName = "";
    this.filter.AadharPhotoFile = "";
  }

  onPanCardChanged(event) {
   
    this.Showpancard = false;
    this.panphotoevent = event;
    if (event.target.files[0] == undefined || event.target.files[0] == null) {
      this.filter.PanCardPhotoFile = "";
    }
    else {
      if (event.target.files[0].type === 'image/jpeg' ||
        event.target.files[0].type === 'image/png' ||
        event.target.files[0].type === 'image/jpg' ||
        event.target.files[0].type === 'application/pdf') {
        if (event.target.files.length > 0) {
          if (event.target.files[0].size < 1048577) {
            this.filter.PanCardPhotoFile = event.target.files[0];
          }
          else {
            alert("Maximum size should be 1 mb");
            this.filter.PanCardPhotoFile = "";
            return true;
          }
        }
      }
      else {
        this.filter.PanCardPhotoFile = "";
      }
    }
  }
  ShowSelectedPanCard() {
    if (this.panphotoevent.target.files[0] == undefined || this.panphotoevent.target.files[0] == null) {
      this.Showpancard = false;
    }
    else {
      this.Showpancard = true;
      this.PanCardName = this.panphotoevent.target.files[0].name;
    }
  }
  ShowPanPic() {
   
    this.fileToUpload = this.panphotoevent.target.files.item(0);
    //Show image preview
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.PanCardimageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
    if (this.fileToUpload.type == 'application/pdf') {
      this.pancardPopuppdf = true;
    }
    else {
      this.pancardPopup = true;
    }
 
  }

  PancloseModal(id: string) {
    this.pancardPopup = false;
  }
  PanpdfcloseModal(id: string) {
    this.pancardPopuppdf = false;
  }
  ClosePanCard() {
    this.Showpancard = false;
    this.panphotoevent = "";
    this.PanCardName = "";
    this.filter.PanCardPhotoFile = "";
  }

  onCancelledChequeChanged(event) {
   
    this.ShowCancelledCheq = false;
    this.CancelledCheqevent = event;
    if (event.target.files[0] == undefined || event.target.files[0] == null) {
      this.filter.CancelledChequePhoto = "";
    }
    else {
      if (event.target.files[0].type === 'image/jpeg' ||
        event.target.files[0].type === 'image/png' ||
        event.target.files[0].type === 'image/jpg' ||
        event.target.files[0].type === 'application/pdf') {
        if (event.target.files.length > 0) {
          if (event.target.files[0].size < 1048577) {
            this.filter.CancelledChequePhoto = event.target.files[0];
          }
          else {
            alert("Maximum size should be 1 mb");
            this.filter.CancelledChequePhoto = "";
            return true;
          }
        }
      }
      else {
        this.filter.CancelledChequePhoto = "";
      }
    }
  }
  ShowSelectedCancelledCheq() {
    if (this.CancelledCheqevent.target.files[0] == undefined || this.CancelledCheqevent.target.files[0] == null) {
      this.ShowCancelledCheq = false;
    }
    else {
      this.ShowCancelledCheq = true;
      this.CancelledCardName = this.CancelledCheqevent.target.files[0].name;
    }
  }
  ShowCancelledCheqPic() {
   
    this.fileToUpload = this.CancelledCheqevent.target.files.item(0);
    //Show image preview
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.CancelledCheqimageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
    if (this.fileToUpload.type == 'application/pdf') {
      this.CancelledCheqPopuppdf = true;
    }
    else {
      this.CancelledCheqPopup = true;
    }
    
  }

  CancelledCheqcloseModal(id: string) {
    this.CancelledCheqPopup = false;
  }
  CancelledCheqpdfcloseModal(id: string) {
    this.CancelledCheqPopuppdf = false;
  }
  CloseCancelledCheq() {
    this.ShowCancelledCheq = false;
    this.CancelledCheqevent = "";
    this.CancelledCardName = "";
    this.filter.CancelledChequePhoto = "";
  }

  InsertAggregratorKycMaster(userid) {
   
    this.UserId = userid;
    var body = new FormData();

    body.append('UserId', this.UserId);
    if (this.passportphotoevent == undefined || this.passportphotoevent == "") {
      body.append('PassportPhotoFile', this.filter.PassportPhotoFile);
    }
    else if (this.passportphotoevent.target.files[0] == undefined || this.passportphotoevent.target.files[0] == "") {
      body.append('PassportPhotoFile', "");
    }
    else {
      body.append('PassportPhotoFile', this.passportphotoevent.target.files[0]);
    }

    if (this.aadhaarphotoevent == undefined || this.aadhaarphotoevent == "") {
      body.append('AadharPhotoFile', this.filter.AadharPhotoFile);
    }
    else if (this.aadhaarphotoevent.target.files[0] == undefined || this.aadhaarphotoevent.target.files[0] == "") {
      body.append('AadharPhotoFile', "");
    }
    else {
      body.append('AadharPhotoFile', this.aadhaarphotoevent.target.files[0]);
    }

    if (this.panphotoevent == undefined || this.panphotoevent == "") {
      body.append('PanCardPhotoFile', this.filter.PanCardPhotoFile);
    }
    else if (this.panphotoevent.target.files[0] == undefined || this.panphotoevent.target.files[0] == "") {
      body.append('PanCardPhotoFile', "");
    }
    else {
      body.append('PanCardPhotoFile', this.panphotoevent.target.files[0]);
    }
    if (this.CancelledCheqevent == undefined || this.CancelledCheqevent == "") {
      body.append('CancelledChequePhoto', this.filter.CancelledChequePhoto);
    }
    else if (this.CancelledCheqevent.target.files[0] == undefined || this.CancelledCheqevent.target.files[0] == "") {
      body.append('CancelledChequePhoto', "");
    }
    else {
      body.append('CancelledChequePhoto', this.CancelledCheqevent.target.files[0]);
    }

    this._userService.InsertAggregratorKycMaster(body).subscribe(
      data => {
       
        if (data) {
          if (data.Message == "Already passport sized photo file exist") {
            this.successmsgs = this._SharedService.showError('Passport sized photo already exist.', '');
          }
          else if (data.Message == "Already aadhaar card file exist") {
            this.successmsgs = this._SharedService.showError('Aadhaar card already exist.', '');
          }
          else if (data.Message == "Already pan card file exist") {
            this.successmsgs = this._SharedService.showError('Pan card already exist.', '');
          }
          else if (data.Message == "Already cancelled cheque file exist") {
            this.successmsgs = this._SharedService.showError('Cancelled cheque already exist.', '');
          }
          else {
            this.PopUpDisplay = false;
            this.Reset();
            this.successmsgs = this._SharedService.showSuccess('Your registration request is submitted successfully. We will get back to you shortly.', '');
          }
        }
      })


  }

  changed(evt) {
   
    this.filter.chkTC = evt.target.checked;
    //alert(evt.target.checked)
  }
  AllowCharacters(event) {
    debugger
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used) |||               ((( || (k >= 48 && k <= 57) -use for numberr)))
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32);
  }
}
