import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    debugger;
  //let url = 'https://forms.gle/droKvvfwGxDzCeN78';
 // window.open(url, "_blank");
  }

}
