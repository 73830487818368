import { Component, OnInit, NgZone } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from './login.service';
import { SharedService } from '../../shared/components/shared.service';
import { Router } from '@angular/router';
import { login } from './login.model';
import { throttleTime } from 'rxjs/operators';




@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  loginForm: FormGroup;
  isForget: boolean;
  Login: login;
  userTypes: any[];
  successmsgs: any[];
  msgs: any[];
  submitted: boolean;
  emailErrMsg: string;
  IsValidEmail: boolean;
  emailId : string;
  

  constructor(private _SharedService: SharedService, private _loginService: LoginService,
    private router: Router, private zone: NgZone) {
    this.isForget = false;
    this.Login = new login();
    this.successmsgs = [];
    this.msgs = [];
    this.submitted = false;
    this.Login = new login();
    this.emailErrMsg = '';
    this.IsValidEmail = false;
    

  }

  ngOnInit() {
    debugger
    this.loginForm = new FormGroup({
      UserName: new FormControl('', [Validators.required]),
      Password: new FormControl('', [Validators.required]),
      EmailId: new FormControl('', [Validators.nullValidator]),
      forgotEmail: new FormControl('', [Validators.nullValidator,Validators.email])
    });
    if (sessionStorage.getItem("UserID") != undefined && sessionStorage.getItem("UserID") != null) {
      //let landingPage = sessionStorage.getItem("landing");
      this.router.navigate(['home']);
    }
  }
  showError(msg: string, details: string) {
    this.msgs = [];
    debugger;
    this.msgs.push({ severity: 'error', summary: msg, detail: "" });
  }

  showSuccess(msg: string, details: string) {
    this.successmsgs = [];
    this.successmsgs.push({ severity: 'success', summary: msg, detail: "" });
  }
  reloadPage() { // click handler or similar
    this.zone.runOutsideAngular(() => {
      location.reload();
      this._SharedService.hideLoader();
    });
  }
  GetValidUserDetails(ValidUserId, menuList) {
    debugger;
    let myobject = {
      "UserId": ValidUserId,
      "menuLists": menuList
    };

    this._loginService.GetValidUserDetails(myobject).subscribe(
      data => {

        if (data.error) {
          console.log(data.error);
          this.showError('An error occured during Authentication. Check log & console for details.', '');
        }
        else {
          this._SharedService.IsLogedIn.isLogedIn = true;
          sessionStorage["accessRights"] = data.strAccessRights;
          sessionStorage["displayMenu"] = data.displayMenu;
          let landingPage = 'home';
          sessionStorage["landing"] = landingPage;
          //this.reloadPage();
          this.router.navigate([landingPage]);

        }
        this._SharedService.hideLoader();
      });
  }
  UserLogin(isValid) {
    debugger;
    if (isValid) {
      sessionStorage.clear();
      localStorage.clear();
      this._SharedService.showLoader();
      this._loginService.LoginProcess(this.Login).subscribe(
        data => {
          debugger;
          if (data != null && data.Code===1) {
            debugger
            let _data=data.Data;
            sessionStorage.clear();
            sessionStorage["UserID"] = JSON.stringify(_data.UserId);
            sessionStorage["RoleID"] = JSON.stringify(_data.RoleId);
            sessionStorage["FullName"] = JSON.stringify(_data.FullName);
            sessionStorage["displayMenu"] = JSON.stringify(_data.menuLists);
            sessionStorage["UserName"] = JSON.stringify(_data.UserName);
            sessionStorage["token"] = _data.Token;
            sessionStorage["IsAdminUser"] = JSON.stringify(_data.IsAdminUser);
            sessionStorage["AggregatorID"] = JSON.stringify(_data.AggregatorID);
            sessionStorage["Points"] = JSON.stringify(_data.Points);
            sessionStorage["ReferralCode"] = _data.ReferralCode;
            sessionStorage["StatusId"] = _data.StatusId;
            sessionStorage["Aggregatorstatus"] = _data.Aggregatorstatus;
            let landingPage = 'home';
            sessionStorage["landing"] = landingPage;         
            //this.router.navigate([landingPage]);
            this.reloadPage();
            
          }
          else {
            sessionStorage.clear();
            this.showError('Wrong credentials Used. Please Try again', '');
          }
          this._SharedService.hideLoader();
        },
        err => {
          sessionStorage.clear();
          localStorage.clear();
          if (err != null && err.error != null)
            this.showError(err.error.error_description, '');
          this._SharedService.hideLoader();
        }
      );


    }

  }

  toggleForget() {
this.isForget= !this.isForget;
  }
  SubmitEmailId() {
      sessionStorage.clear();
      localStorage.clear();
      this._SharedService.showLoader();
      let _model={
        Email:this.emailId
      };
      this._loginService.ForgotPassword(_model).subscribe(
        data => {
          debugger;
          if (data != null) {
            debugger
            this._SharedService.hideLoader();
            this.showSuccess("New Password sent successfully",null);
            this.toggleForget();
          }
          else {
            this.showError('Wrong credentials Used. Please Try again', '');
          }
          this._SharedService.hideLoader();
        },
        err => {
          if (err != null && err.error != null)
            this.showError(err.error.error_description, '');
          this._SharedService.hideLoader();
        }
      );


    }
  
}


