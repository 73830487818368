import * as moment from 'moment';

export class userdata {
  UserId: string;
  FirstName: string;
  StateID: string;
  LastName: string;
  Email: string;
  ContactNo: string;
  selectedDOB: string;
 
  Address: string;
  CityID: string;
  PinCode: string;
  AccountNo: string;
  BankName: string;
  BranchName: string;
  IFSCcode: string;
  RefferalCode: string;
  AggregratorID: string;
  createdUserID: string;
  SearchText: string;
  Organization: string;
  employmentstatus: string;
  LanguageID: string;
  Locality: string;
  Earning: number;
  ValidityDays: string;
  RefferalCodeValue: string;
  onpointper: string;
  oncashper: string;
  onpointrs: string;
  oncashrs: string;
  ApprovalStatus: string;
  Remark: string;
  PassportPhotoFile: string;
  AadharPhotoFile: string;
  PanCardPhotoFile: string;
  CancelledChequePhoto: string;
  RemoveKyc: string;
  Status: string;
  StatusId: string;
  Isupdate: boolean;
  Password: string;
  constructor() {
    this.UserId = "";
    this.FirstName = "";
    this.StateID = "";
    this.LastName = "";
    this.Email = "";
    this.ContactNo = "";
    this.selectedDOB = "";
    this.Address = "";
    this.CityID = "";
    this.PinCode = "";
    this.AccountNo = "";
    this.BankName = "";
    this.BranchName = "";
    this.IFSCcode = "";
    this.RefferalCode = "";
    this.AggregratorID = "";
    this.createdUserID = "";
    this.SearchText = "";
    this.Organization = "";
    this.employmentstatus = "";
    this.LanguageID = "";
    this.Locality = "";
    this.Earning = 0;
    this.RefferalCodeValue = "";
    this.ValidityDays = "";
    this.onpointper = "";
    this.oncashper = "";
    this.onpointrs = "";
    this.oncashrs = "";
    this.ApprovalStatus = "";
    this.Remark = "";
    this.PassportPhotoFile = "";
    this.AadharPhotoFile = "";
    this.PanCardPhotoFile = "";
    this.CancelledChequePhoto = "";
    this.RemoveKyc = "";
    this.StatusId = "";
    this.Status = "";
    this.Isupdate = false;
    this.Password = "";
  }
}

export class CardDetails {
  Merchant: string;
  credits: string;
  Earning: string;
  Campaign: string;
  constructor() {
    this.Merchant = "";
    this.credits = "";
    this.Earning = "";
    this.Campaign = "";
  }

}
export class Language {
  LanguageID: string;
  Language: string;

  constructor() {
    this.LanguageID = "";
    this.Language = "";

  }
}
