import { Injectable } from '@angular/core';
import { SharedService } from '../../shared/components/shared.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',   
  })
};
const httpOptionsencoded = {
  headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',

  })
};
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private _http: HttpClient, private _sharedService: SharedService) { }

  LoginProcess(body: any): Observable<any> {
    debugger;
    return this._http.post(this._sharedService.getAPIurl() + '/Login/LoginProcess', body, httpOptions).pipe(
      map((response: Response) => <any>response),
      catchError(this._errorHandler));
        
  }
  GetDetailMenu(body: any): Observable<any> {
    debugger;
    return this._http.post(this._sharedService.getAPIurl() + '/Login/GetDetailMenu', body, httpOptions).pipe(
      map((response: Response) => <any>response),
      catchError(this._errorHandler));

  }
  
  GetValidUserDetails(body: any): Observable<any> {
    return this._http.post(this._sharedService.getAPIurl() + '/Login/GetValidUserDetails', body, httpOptions).pipe(
      map((response: Response) => <any>response),
      catchError(this._errorHandler));

  }
  ForgotPassword(body: any): Observable<any> {
    debugger;
    return this._http.post(this._sharedService.getAPIurl() + '/Login/ForgotPassword', body, httpOptions).pipe(
      map((response: Response) => <any>response),
      catchError(this._errorHandler));
        
  }
  _errorHandler(error: Response) {
    return observableThrowError(error || "Internal server error");
  }
}
