
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { homeservice } from '../home/home.service';
import { debounce } from 'rxjs/operators';
import { Chart } from "../../../assets/js/chart.js";
import { userdata, CardDetails } from '../../modules/Masters/create-cmcuser/create-cmcuser.model';
import * as moment from 'moment';
import { SharedService } from '../../shared/components/shared.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  CardDetails: CardDetails;
  public chart: Chart;
  public secchart: Chart;
  WeekBarDetails: any;
  Weekdata: any;
  CampaignBarDetails: any;
  CampaignWeekdata: any;
  ShowCMCUser: boolean;
  filter: userdata;
  successmsgs: any[];

  constructor(private router: Router, private _DashboardCMC: homeservice, private _SharedService: SharedService) {
    this.filter = new userdata();
    this.CardDetails = new CardDetails();
  }

  ngOnInit() {
    debugger;
    if (sessionStorage.getItem("IsAdminUser") == "false") {
      this.ShowCMCUser = true;
      this.GetDashboardCardDetails();
      this.GetBarGraphCMCMerchant();
      this.GetBarGraphCMCActiveCamp();
    }
    else if (sessionStorage.getItem("IsAdminUser") == undefined || sessionStorage.getItem("IsAdminUser") == null) {
      this.successmsgs = this._SharedService.showSuccess('Your profile is updated successfully.', '');
      sessionStorage.setItem('IsAdminUser', "false");
      this.ShowCMCUser = true;
      this.GetDashboardCardDetails();
      this.GetBarGraphCMCMerchant();
      this.GetBarGraphCMCActiveCamp();
    }
    else {

    }

    if (sessionStorage.getItem("landing") != undefined && sessionStorage.getItem("landing") != null) {
      let landingPage = sessionStorage.getItem("landing");
      if (landingPage != 'home')
        this.router.navigate([landingPage]);

    }

  }

  GetDashboardCardDetails() {
    debugger;
    let filter = new userdata();
    filter.AggregratorID = sessionStorage.getItem("AggregatorID");
    filter.UserId = sessionStorage.getItem("UserID");
    this._DashboardCMC.GetDashboardCardDetails(filter).subscribe(
      data => {
        if (data) {
          this.CardDetails = data.Table[0];

          var x = String(this.CardDetails.credits);
          x = x.toString();
          var lastThree = x.substring(x.length - 3);
          var otherNumbers = x.substring(0, x.length - 3);
          if (otherNumbers != '')
            lastThree = ',' + lastThree;
          var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
          this.CardDetails.credits = res;

        }
      });
  }
  GetBarGraphCMCMerchant() {
    debugger;
    let filter = new userdata();
    filter.AggregratorID = sessionStorage.getItem("AggregatorID");
    filter.UserId = sessionStorage.getItem("UserID");
    this._DashboardCMC.GetBarGraphCMCMerchant(filter).subscribe(
      data => {
        if (data) {
          this.WeekBarDetails = data.Table[0];
          this.Weekdata = data.Table1[0];
          this.chart = new Chart("canvas", {
            type: "bar",
            data: {
              labels: [moment(this.WeekBarDetails.Weekstart6).format(' DD MMM YY '), moment(this.WeekBarDetails.Weekstart5).format(' DD MMM YY '), moment(this.WeekBarDetails.Weekstart4).format(' DD MMM YY '), moment(this.WeekBarDetails.Weekstart3).format(' DD MMM YY '), moment(this.WeekBarDetails.Weekstart2).format(' DD MMM YY '), moment(this.WeekBarDetails.Weekstart1).format(' DD MMM YY ')],
              datasets: [
                {
                  label: "No of.merchants",
                  data: [this.Weekdata.week6, this.Weekdata.week5, this.Weekdata.week4, this.Weekdata.week3, this.Weekdata.week2, this.Weekdata.week1],
                  backgroundColor: [
                    "rgba(255, 99, 132, 0.7)",
                    "rgba(54, 162, 235, 0.7)",
                    "rgba(255, 206, 86, 0.7)",
                    "rgba(75, 192, 192, 0.7)",
                    "rgba(153, 102, 255, 0.7)",
                    "rgba(255, 159, 64, 0.7)"
                  ],
                  borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 159, 64, 1)"
                  ],
                  borderWidth: 1
                }
              ]
            },
            options: {
              tooltips: {
                enabled: true,
                callbacks: {
                  title: function () { }
                },
                displayColors: false,
              },
              legend: {
                "display": false
              },
              scales: {

                yAxes: [
                  {

                    ticks: {
                      beginAtZero: true,
                      //steps: 5,
                      //stepValue: 5,
                      //max: 50,
                      //min: 0
                    }
                  }
                ]
              }
            }
          });
        }
      });
  }
  GetBarGraphCMCActiveCamp() {
    debugger;
    let filter = new userdata();
    filter.AggregratorID = sessionStorage.getItem("AggregatorID");
    filter.UserId = sessionStorage.getItem("UserID");
    this._DashboardCMC.GetBarGraphCMCActiveCamp(filter).subscribe(
      data => {
        if (data) {
          this.CampaignBarDetails = data.Table[0];
          this.CampaignWeekdata = data.Table1[0];
          this.secchart = new Chart("canvas2", {
            type: "bar",
            data: {
              labels: [moment(this.CampaignBarDetails.Weekstart6).format(' DD MMM YY '), moment(this.CampaignBarDetails.Weekstart5).format(' DD MMM YY '), moment(this.CampaignBarDetails.Weekstart4).format(' DD MMM YY '), moment(this.CampaignBarDetails.Weekstart3).format(' DD MMM YY '), moment(this.CampaignBarDetails.Weekstart2).format(' DD MMM YY '), moment(this.CampaignBarDetails.Weekstart1).format(' DD MMM YY ')],

              datasets: [
                {

                  data: [this.CampaignWeekdata.costweek6, this.CampaignWeekdata.costweek5, this.CampaignWeekdata.costweek4, this.CampaignWeekdata.costweek3, this.CampaignWeekdata.costweek2, this.CampaignWeekdata.costweek1],
                  label: ["Deal value"],
                  backgroundColor: [
                    "rgba(255, 99, 132, 0.7)",
                    "rgba(54, 162, 235, 0.7)",
                    "rgba(255, 206, 86, 0.7)",
                    "rgba(75, 192, 192, 0.7)",
                    "rgba(153, 102, 255, 0.7)",
                    "rgba(255, 159, 64, 0.7)"
                  ],
                  borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 159, 64, 1)"
                  ],
                  borderWidth: 1,
                }
              ]

            },
            options: {

              tooltips: {
                enabled: true,
                callbacks: {
                  title: function () { }
                },
                displayColors: false,
              },
              legend: {
                "display": false
              },

              //scaleShowVerticalLines: true,

              //responsive: true,
              //tooltips: {
              //  callbacks: {
              //    label: function (tooltipItem, data) {
              //      let label = "Campaings:";

              //      let label2 = "Values:";
              //      return [label, label2];
              //      //},
              //      //afterBody: function (tooltipItem, CampaignWeekdata) {
              //      //  return this.CampaignWeekdata+"\n"+"secondLine"
              //      //} 
              //    }
              //  }
              //},
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true
                    }
                  }
                ]
              }
            }


          });
        }
      });

  }



}
