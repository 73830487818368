import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ChangePassword } from './change-password.model';
import { ChangePasswordService } from './change-password.service';
import { SharedService } from '../../shared/components/shared.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  constructor(private _SharedService: SharedService, private _changePassword: ChangePasswordService) { }
  successmsgs: any;
  msgs: any;
  changePasswordData: FormGroup;
  submitted: boolean;
  changePassword: ChangePassword
  loginuser: any;
  link: string;

  ngOnInit() {
    debugger
    this.loginuser = new Object();
    this.changePassword = new ChangePassword();

    if (sessionStorage.length != 0) {
      debugger;
      this.loginuser = JSON.parse(sessionStorage.getItem("userInfo"));
      if (sessionStorage.getItem("UserID") != undefined && sessionStorage.getItem("UserID") != null) {
        //this.loginuser.UserID = sessionStorage.getItem("UserID");
        this.changePassword.UserId = parseInt(sessionStorage.getItem("UserID"));
        this.changePassword.UserName = JSON.parse(sessionStorage.getItem("UserName"));
        this.changePassword.AggregatorID = parseInt(sessionStorage.getItem("AggregatorID"));
      }
    } else {
      this.loginuser = { "fullName": "", "UserID": 0, "RoleId": 0 };
    }
    this.link = this._SharedService.getAPIurl();
    this.submitted = false;
    this.Validation();
  }
  Validation() {
    this.changePasswordData = new FormGroup({
        oldPassword: new FormControl('', [Validators.required])
      , password: new FormControl('', Validators.required)
      , confirmPassword: new FormControl('', Validators.required)

    },
      {
        validators: this.password.bind(this)
      }
    );
  }
  password(formGroup: FormGroup) {
    const { value: password } = formGroup.get('password');
    const { value: confirmPassword } = formGroup.get('confirmPassword');
    return password === confirmPassword ? null : { passwordNotMatch: true };
  }
  BackToHome() {
    this.changePassword = new ChangePassword();
  }
  ChangePassword() {
    debugger
    if (this.changePasswordData.valid) {
      this._SharedService.showLoader();
      this._changePassword.ChangePassword(this.changePassword).subscribe(
        data => {
          debugger;
          if (data != null) {
            debugger
            if(data.Code==1){
              this.successmsgs = this._SharedService.showSuccess('Password changed successfully', '');
              this.changePassword = new ChangePassword();
            }else{
              this.msgs =  this._SharedService.showError(data.Message, '');
            }
          }
          else {
            this.msgs =  this._SharedService.showError('Wrong credentials Used. Please Try again', '');
          }
          this._SharedService.hideLoader();
        },
        err => {
          if (err != null && err.error != null)
            this._SharedService.showError(err.error.error_description, '');
          this._SharedService.hideLoader();
        }
      );
    }
  }

}
