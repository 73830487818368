import * as moment from 'moment';

export class userdata {
  UserId: string;
  FirstName: string;
  StateID: string;
  LastName: string;
  Email: string;
  ContactNo: string;
  selectedDOB: string;
  Status: number;
  Address: string;
  CityID: string;
  PinCode: string;
  AccountNo: string;
  BankName: string;
  BranchName: string;
  IFSCcode: string;
  RefferalCode: string;
  AggregratorID: string;
  createdUserID: string;
  SearchText: string;
  Organization: string;
  employmentstatus: string;
  LanguageID: string;
  Locality: string;
  PassportPhotoFile: string;
  AadharPhotoFile: string;
  PanCardPhotoFile: string;
  CancelledChequePhoto: string;
  chkTC: boolean;
  constructor() {
    this.UserId = "";
    this.FirstName = "";
    this.StateID = "";
    this.LastName = "";
    this.Email = "";
    this.ContactNo = "";
    this.selectedDOB = "";
    this.Status = 1;
    this.Address = "";
    this.CityID = "";
    this.PinCode = "";
    this.AccountNo = "";
    this.BankName = "";
    this.BranchName = "";
    this.IFSCcode = "";
    this.RefferalCode = "";
    this.AggregratorID = "";
    this.createdUserID = "";
    this.SearchText = "";
    this.Organization = "";
    this.employmentstatus = "";
    this.LanguageID = "";
    this.Locality = "";
    this.PassportPhotoFile = "";
    this.AadharPhotoFile = "";
    this.PanCardPhotoFile = "";
    this.CancelledChequePhoto = "";
    this.chkTC = false;
  }
}

export class CardDetails {
  Merchant: string;
  credits: string;
  Earning: string;
  Campaign: string;
  constructor() {
    this.Merchant = "";
    this.credits = "";
    this.Earning = "";
    this.Campaign = "";
  }
}
