import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, debounce } from 'rxjs/operators';
import { SharedService } from '../../shared/components/shared.service'

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})

export class newagentregistrationservice {
  private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  constructor(private _sharedService: SharedService, private _http: HttpClient) { }

  GetState(): Observable<any> {
    return this._http.get(this._sharedService.getAPIurl() + '/NewAgentRegi/GetState').pipe(
      map((response: Response) => <any>response),
      catchError(this._errorHandler));
  }
  GetCity(body: any): Observable<any> {
    
    return this._http.post(this._sharedService.getAPIurl() + '/NewAgentRegi/GetCity', body, httpOptions).pipe(
      map((response: Response) => <any>response),
      catchError(this._errorHandler));
  }
  GetLanguage(): Observable<any> {
    return this._http.get(this._sharedService.getAPIurl() + '/NewAgentRegi/GetLanguage').pipe(
      map((response: Response) => <any>response),
      catchError(this._errorHandler));
  }
  CheckForCreateUserEmail(body: any): Observable<any> {
    
    return this._http.post(this._sharedService.getAPIurl() + '/NewAgentRegi/CheckForCreateUserEmail', body, httpOptions).pipe(
      map((response: Response) => <any>response),
      catchError(this._errorHandler));
  }
  CreateNewagentRegi(body: any): Observable<any> {
    
    return this._http.post(this._sharedService.getAPIurl() + '/NewAgentRegi/CreateNewagentRegi', body, httpOptions).pipe(
      map((response: Response) => <any>response),
      catchError(this._errorHandler));
  }
  SendPasswordToEmail(body: any): Observable<any> {
    
    return this._http.post(this._sharedService.getAPIurl() + '/NewAgentRegi/SendPasswordToEmail', body, httpOptions).pipe(
      map((response: Response) => <any>response),
      catchError(this._errorHandler));
  }
  InsertAggregratorKycMaster(body: any): Observable<any> {
    
    return this._http.post(this._sharedService.getAPIurl() + '/NewAgentRegi/InsertAggregratorKycMaster', body).pipe(
      map((response: Response) => <any>response),
      catchError(this._errorHandler));
  }
  _errorHandler(error: Response) {
    //this._SharedService.hideLoader();
    return Observable.throw(error || "Internal server error");
  }
}
