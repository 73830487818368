import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SharedService } from '../app/shared/components/shared.service';
import { AppRoutingModule } from './app-routing.module';

import { APP_INITIALIZER } from '@angular/core';
import { AppConfig } from './app.config';
import { MenuComponent } from '../app/shared/components/menu/Menu.component';
import { AppComponent } from './app.component';
import { LoginComponent } from './modules/login/login.component';
import { LoaderComponent } from '../app/shared/components/fullloader/Loader.component';
import { HomeComponent } from './modules/home/home.component';
import { NotFoundComponent } from './shared/components/notfound/NotFound.component';
//-------Third party controls----------------------------------------------//
import { GrowlModule } from 'primeng/growl';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { ChangePasswordComponent } from './modules/change-password/change-password.component';
import { ModelPopupComponent } from './model-popup/model-popup.component';
import { NewAgentRegistrationComponent } from './modules/new-agent-registration/new-agent-registration.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { DatePipe } from '@angular/common';
import { FeedbackComponent } from './modules/feedback/feedback.component';

export function initializeApp(appConfig: AppConfig) {
  return () =>
    appConfig.load();
};

@NgModule({
  declarations: [
    AppComponent, LoginComponent, MenuComponent, LoaderComponent, HomeComponent, NotFoundComponent, ChangePasswordComponent, ModelPopupComponent, NewAgentRegistrationComponent
    , FeedbackComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    GrowlModule,
    ReactiveFormsModule, MultiSelectModule, CalendarModule ,DialogModule,
    ButtonModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    SharedService, DatePipe,
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig],
      multi: true
    }
    , {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
