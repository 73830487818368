import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { SharedService } from '../shared.service';
import { LoginModels } from '../../../shared/models/LoginModels';



@Component({
    selector: 'app-menu',
    templateUrl: './Menu.html'

})
export class MenuComponent implements OnInit {

    public menu: any[];
    public submenu: any[];
    errormessage: any;
    public RoleID: number;
    public AccessRights: any;
    public menudata: any[];
    public userDetails: LoginModels;
    public backurl: string;
    public baseurl: string;

    constructor( private _sharedService: SharedService,
        private router: Router, @Inject(DOCUMENT) private document: any) {
        this.RoleID = 0;
        this.userDetails = new LoginModels();
        this.backurl = this._sharedService.getURL();
        this.baseurl = this._sharedService.getAngURL();
        //if (sessionStorage.getItem("displayMenu") != undefined && sessionStorage.getItem("displayMenu") != null) {
        //    this.menu = JSON.parse(sessionStorage.getItem("displayMenu"));
        //    this._sharedService.setAccessRights(JSON.parse(sessionStorage.getItem("accessRights")));
        //}
    }

  getMenu(): void {
    //debugger;
    this.menu = JSON.parse(sessionStorage.getItem("displayMenu"));
   }

    redirectClick(pathName: string) {
        //debugger;
        //var path = "";
        //let IsExternalUrl = false;
        let currenturl = this.router.url;
        if (currenturl === '/' + pathName) {
            //this.router.navigateByUrl(pathName);
            //this.document.location.href = pathName;
            localStorage.clear();
            this.document.location.reload();
            return;
        }
        //switch (pathName) {
        //    //case "dashboard": {
        //    //        //window.location.href = "http://stagingiro.ufomoviez.com/Html/index.html";
        //    //        window.location.href = this._sharedService.getURL() +'/Dashboard.html';
        //    //    break;
        //    //}
        //    case "iroList": {

        //        path = this._sharedService.getURL() + "/Html/iroList";
        //        IsExternalUrl = true;
        //        break;
        //    }
        //    case "client": {

        //        path = this._sharedService.getURL() + "/Html/client";
        //        IsExternalUrl = true;
        //        break;
        //    }
        //    case "uid": {

        //        path = this._sharedService.getURL() + "/Html/uid";
        //        IsExternalUrl = true;
        //        break;
        //    }
        //    case "agency": {

        //        path = this._sharedService.getURL() + "/Html/agency";
        //        IsExternalUrl = true;
        //        break;
        //    }
        //    case "advertisement": {

        //        path = this._sharedService.getURL() + "/Html/advertisement";
        //        IsExternalUrl = true;
        //        break;
        //    }
        //    case "DraftRO": {

        //        path = this._sharedService.getURL() + "/Html/DraftRO";
        //        IsExternalUrl = true;
        //        break;
        //    }
        //    case "overbooking": {

        //        path = this._sharedService.getURL() + "/Html/overbooking";
        //        IsExternalUrl = true;
        //        break;
        //    }
        //    case "suggestedPauses": {

        //        path = this._sharedService.getURL() + "/Html/suggestedPauses";
        //        IsExternalUrl = true;
        //        break;
        //    }
        //    case "clientProfile": {

        //        path = this._sharedService.getURL() + "/Html/clientProfile";
        //        IsExternalUrl = true;
        //        break;
        //    }
        //     case "ReportMenu": {

        //        path = this._sharedService.getURL() + "/Html/ReportMenu";
        //        IsExternalUrl = true;
        //        break;
        //    }
        //    case "ViewRo": {

        //        path = this._sharedService.getURL() + "/Html/ViewRo";
        //        IsExternalUrl = true;
        //        break;
        //    }
        //    default: {
        //        path = pathName;                
        //        IsExternalUrl = false;
        //        break;
        //    }
        //}
        //if (IsExternalUrl)
        //    this.document.location.href = path;
        //else
        this.router.navigate([pathName]);
        //return path;

    }

    ngOnInit() {
        this.getMenu();

    }

}
