import { Injectable } from '@angular/core';
import { SharedService } from '../shared/components/shared.service';
import { Router } from '@angular/router';
//import { ErrorDialogService } from '../error-dialog/errordialog.service';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(private _sharedService: SharedService, private router: Router) { }//public errorDialogService: ErrorDialogService
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token: string = this._sharedService.getToken(); //localStorage.getItem('token');

        if (token != undefined && token != null) {
            request = request.clone(
                //{ headers: request.headers.set('Authorization', 'Bearer ' + token) }
                {
                    setHeaders: {
                        Authorization: `Bearer ${token}`,
                       // 'Content-Type': `application/json`
                    }
                }
            );
        }
        // else {
        //     sessionStorage.clear();
        //     localStorage.clear();
        //     this._sharedService.IsLogedIn.isLogedIn = false;
        //     this.router.navigate(['/login']);
        // }
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    //console.log('event--->>>', event);
                    // this.errorDialogService.openDialog(event);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                debugger
                if (error.status == 401) {
                    alert(error.error.Message);
                    sessionStorage.clear();
                    localStorage.clear();
                    this._sharedService.IsLogedIn.isLogedIn = false;
                    this.router.navigate(['/login']);
                }
                let data = {};
                data = {
                    reason: error && error.error && error.error.reason ? error.error.reason : '',
                    status: error.status
                };
                //this.errorDialogService.openDialog(data);
                return throwError(error);
            }));
    }
}