import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, debounce } from 'rxjs/operators';
import { SharedService } from '../../../app/shared/components/shared.service';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class homeservice {
  private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  constructor(private _sharedService: SharedService, private _http: HttpClient) { }

  GetDashboardCardDetails(body: any): Observable<any> {
    return this._http.post(this._sharedService.getAPIurl() + '/CMCUsers/GetDashboardCardDetails', body, httpOptions).pipe(
      map((response: Response) => <any>response),
      catchError(this._errorHandler));
  }
  GetBarGraphCMCMerchant(body: any): Observable<any> {
    return this._http.post(this._sharedService.getAPIurl() + '/CMCUsers/GetBarGraphCMCMerchant', body, httpOptions).pipe(
      map((response: Response) => <any>response),
      catchError(this._errorHandler));
  }
  GetBarGraphCMCActiveCamp(body: any): Observable<any> {
    return this._http.post(this._sharedService.getAPIurl() + '/CMCUsers/GetBarGraphCMCActiveCamp', body, httpOptions).pipe(
      map((response: Response) => <any>response),
      catchError(this._errorHandler));
  }
  
  _errorHandler(error: Response) {
    //this._SharedService.hideLoader();
    return Observable.throw(error || "Internal server error");
  }
}
