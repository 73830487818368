import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-pagenotfound',
    templateUrl: './403.html', 
})
export class NotFoundComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}