import { Injectable, OnDestroy, EventEmitter, Output } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { UserRights } from './enums';


export interface ILoader {
  isLoading: boolean;
}
export interface ILogin {
  isLogedIn: boolean;
}
//export class Param {
//    name: string;
//    value: string;
//    constructor(_name: string, _value: string) {
//        this.name = _name;
//        this.value = _value;
//    }
//}

export class Rights {
  page: string;
  rightsToView: boolean;
  rightsToAdd: boolean;
  rightsToEdit: boolean;
  rightsToDelete: boolean;
}



@Injectable()
export class SharedService {


  public _isLoading = new BehaviorSubject<boolean>(false);
  public _isPLoading = new BehaviorSubject<boolean>(false);
  public _isLogedIn = new BehaviorSubject<boolean>(false);
  public _sharedData: any = null;
  private api_url: string //= AppConfig.settings.apiServer.api_url;
  private url: string //= AppConfig.settings.apiServer.url;
  private env: string //= AppConfig.settings.env.name;
  private aurl: string //= AppConfig.settings.apiServer.aurl;
  private accessRights: Rights[];
  private mediaUrl: string;
  private InvoiceUrl: string;
  private CMCInvoiceUrl: string;

  private DefaultAgency: string = '35';
  // public mapSelectionStateVsBetSlip = new BehaviorSubject<Map<number, number[]>>(new Map());
  successmsgs: any[];
  msgs: any[];
  infomsgs: any[];
  constructor() {

  }
  getDefaultAgency() {
    return this.DefaultAgency;
  }

  loader: ILoader = { isLoading: false };
  ploader: ILoader = { isLoading: false };
  IsLogedIn: ILogin = { isLogedIn: false };
  //params: Param[];

  showLoader() {
    this.loader.isLoading = true;
  }

  hideLoader() {
    this.loader.isLoading = false;
  }

  showPLoader() {
    this.ploader.isLoading = true;
  }

  hidePLoader() {
    this.ploader.isLoading = false;
  }
  getAPIurl() {
    return this.api_url;
  }
  getURL() {
    return this.url;
  }
  getENV() {
    return this.env;
  }
  setAPIurl(val: string) {
    this.api_url = val;
  }
  setURL(val: string) {
    this.url = val;
  }
  setENV(val: string) {
    this.env = val;
  }
  setMediaUrl(val: string) {
    this.mediaUrl = val;
  }
  setInvoiceUrl(val: string) {
    this.InvoiceUrl = val;
  }
  setCMCInvoiceUrl(val: string) {
    this.CMCInvoiceUrl = val;
  }
  getInvoiceUrl() {
    return this.InvoiceUrl;
  }
  getCMCInvoiceUrl() {
    return this.CMCInvoiceUrl;
  }
  getAngURL() {
    return this.aurl;
  }
  getMediaURL() {
    return this.mediaUrl;
  }

  getUserDetails() {

  }
  setAccessRights(rights: Rights[]) {
    debugger;
    this.accessRights = rights;
  }

  //addParam(name: string, value: string) {
  //    this.params.push(new Param(name, value));
  //}
  //removeParam(name: string) {

  //}
  //clearParam() {
  //    this.params = [];
  //}
  //getParams() {
  //    return this.params;
  //}
  getToken() {
    return sessionStorage.getItem('token');
  }
  checkRights(module: string, right: string): boolean {
    let Rights = null;
    let retval = false;
    if (this.getENV() == 'dev')
      retval = true;
    else {
      Rights = this.accessRights.find(r => r.page == module && (
        (right == UserRights.add && r.rightsToAdd == true) ||
        (right == UserRights.edit && r.rightsToEdit == true) ||
        (right == UserRights.delete && r.rightsToDelete == true) ||
        (right == UserRights.view && r.rightsToView == true)
      ));
    }

    if (Rights != undefined && Rights != null)
      retval = true;
    return retval;
  }

  NumberToWords(num) {
    var a = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ', 'Thirteen ', 'Fourteen ', 'Fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen '];
    var b = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
    var str = '';
    var no = num;
    var strNum = parseFloat(no).toFixed(2).toString();

    if ((strNum).split('.').length == 2) {
      num = parseInt((strNum).split(".")[0]);

      if ((num).length > 9)
        return '';
    }
    else {
      if ((strNum).length > 9)
        return '';
    }

    let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);

    if (!n)
      return;

    str += (parseInt(n[1]) != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore ' : '';
    str += (parseInt(n[2]) != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lacs ' : '';
    str += (parseInt(n[3]) != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand ' : '';
    str += (parseInt(n[4]) != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred ' : '';

    if ((strNum).split(".").length == 2 && strNum.split(".")[1] != '00') {
      str += (parseInt(n[5]) != 0) ? (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) : '';
      str += (parseInt((strNum).split(".")[1]) != 0) ? ((str != '') ? 'rupees and ' : '') + b[((strNum).split(".")[1])[0]] + ' ' + a[((strNum).split(".")[1])[1]] + 'paise only ' : '';
    }
    else {
      str += (parseInt(n[5]) != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'rupees only' : 'rupees only';
    }

    return str;
  }

  showError(msg: string, details: string) {
    this.msgs = [];
    this.msgs.push({ severity: 'error', summary: msg, detail: "" });
    return this.msgs;
  }

  showSuccess(msg: string, details: string) {
    this.successmsgs = [];
    this.successmsgs.push({ severity: 'success', summary: msg, detail: "" });
    return this.successmsgs;
  }
  showInfo(msg: string, details: string) {
    this.infomsgs = [];//warn
    this.infomsgs.push({ severity: 'info', summary: msg, detail: "" });
    return this.infomsgs;
  }
}

