import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-loader',
    templateUrl: './Loader.html'
    //styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}