export class login {
    UserName: string;
    Password: string;
    UserTypeId: string;
    EmailId: string;
    RoleId: string;
    constructor() {
        this.UserName = '';
        this.Password = '';
        this.UserTypeId = '';
      this.EmailId = '';
      this.RoleId = '';
  }

}
