import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './modules/login/login.component';
import { HomeComponent } from './modules/home/home.component';
import { ChangePasswordComponent } from './modules/change-password/change-password.component';
import { NewAgentRegistrationComponent } from './modules/new-agent-registration/new-agent-registration.component';
import { FeedbackComponent } from './modules/feedback/feedback.component';


const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'CMCSelfRegistration', component: NewAgentRegistrationComponent },
  { path: 'home', component: HomeComponent, data: { title: 'Home' } },
  { path: 'changepassword', component: ChangePasswordComponent , data:{title: 'Change Password'}},
  {
    path: 'master', data: { title: 'Master Views' },
    loadChildren: () => import('./modules/Masters/masters.module').then(m => m.MastersModule)
  },
  {
    path: 'report', data: { title: 'Report Views' },
    loadChildren: () => import('./modules/Reports/reports.module').then(r => r.ReportsModule)
  },
  { path: 'Feedback', component: FeedbackComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
