
import { Component, ChangeDetectorRef } from '@angular/core';
import { RouterModule, Routes, Router, NavigationEnd, ActivatedRoute, NavigationStart, NavigationCancel, NavigationError } from '@angular/router';
import { map, filter } from 'rxjs/operators';
import { SharedService } from '../app/shared/components/shared.service';
import { CommonService } from './shared/components/common.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'PlexiAds';
  PageHeader: any;
  userInfo: any;
  userDetails: any;
  userfullname: string;
  department: string;
  Points: any;
  UserId: string;
  ReferralCode: any;
  AggregatorAccountBalance: any = [];
  //IsLogedIn: boolean;
  ShowCMCUser: boolean;
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private _commonService: CommonService, 
    public _sharedService: SharedService, private cdRef: ChangeDetectorRef) {
    debugger
    this.PageHeader = "";
    this.userfullname = "";
    this.ShowCMCUser = false;
    if (sessionStorage.getItem("UserID") != undefined && sessionStorage.getItem("UserID") != null) {
      this._sharedService.IsLogedIn.isLogedIn = true;
      this.userInfo = JSON.parse(sessionStorage["FullName"]);
      this.userfullname = this.userInfo;
      this.UserId = sessionStorage.getItem("UserID");
      if (sessionStorage.getItem("AggregatorID") == "3" && sessionStorage.getItem("RoleID") == "14") {
        this.ShowCMCUser = true;
        this.Points = sessionStorage.getItem("Points");
        this.ReferralCode = sessionStorage.getItem("ReferralCode");
        debugger; 
      }
   }
    else {
      this._sharedService.IsLogedIn.isLogedIn = false;
    }
   
  }

  ngOnInit() {
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child) {
            //debugger;
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data['title']) {
              return child.snapshot.data['title'];
            } else {
              return null;
            }
          }
          return null;
        })).subscribe((title: any) => {
          this.PageHeader = title;
        });

    this.router.events.subscribe(event => {
      switch (true) {
        case event instanceof NavigationStart: {
          this._sharedService.showLoader();
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this._sharedService.hideLoader();
          break;
        }
        default: {
          break;
        }
      }
    });
    this.loadScript('assets/themedata/scripts/klorofil-common.js');

  }

  logOut() {
    sessionStorage.clear();
    localStorage.clear();
    this._sharedService.IsLogedIn.isLogedIn = false;
    this.router.navigate(['']);

  }
  UpdateProfileData() {

      this._commonService.getAggregatorAccountBalance(this.UserId).subscribe(
        data => {
          debugger;
          if (data) {
            this.AggregatorAccountBalance = data;
            this.Points = this.AggregatorAccountBalance[0].Points
            sessionStorage["Points"] = JSON.stringify(this.Points);
          }
        });

  }
  GoToHome() {
    this.router.navigate(['home']);
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

}
